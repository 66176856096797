import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import MenuBarDrawer from '../components/menuBarDrawer';
import MenuBarOnly from '../components/menuBarOnly';
import { getCookie, getFromLocalStorage } from './utils';
import { fireTracking } from './googleAnalytics';

const GENERAL_ROOT = '/login';
const isAuthenticated = () => { return !!getCookie('userToken'); };
const renderRoute = (props) => {
  const { component, isMenuDrawer, title, type, path, isMenuBarOnly } = props;
  return (
        isMenuBarOnly ?
          <MenuBarOnly>
            <Route { ...props } path={ path } component={ component } />
          </MenuBarOnly>
        : (
            isMenuDrawer ?
              <MenuBarDrawer title={ title } type={ type }>
                <Route { ...props } path={ path } component={ component } />
              </MenuBarDrawer>
            :
              <Route { ...props } path={ path } component={ component } />
            )
  );
};
const AuthRoute = (props) => {
  const { component, authRequired, isMenuDrawer, title, type, path, isMenuBarOnly, isAdmin } = props;
  fireTracking();
  if (authRequired) {
    if (isAuthenticated()) {
      if (isAdmin && getFromLocalStorage().role != 'admin') {
        return <Redirect to={ GENERAL_ROOT } />;
      }
      return renderRoute(props);
    }

    return <Redirect to={ GENERAL_ROOT } />;
  }
  return renderRoute(props);
};
export default AuthRoute;
