import Api from '../api';
import {DASHBOARD_DETAILS,DASHBOARD_DETAILS_URL, DASHBOARD_SMS_STATS, DASHBOARD_SMS_STATS_URL} from '../lib/constant';
import {routeToLogin, getCookie} from '../lib/utils';

export function dashboardDetails(){
  const d = new Date();
  const request = Api.get(DASHBOARD_DETAILS_URL+"?date="+d.getDate()+"&month="+d.getMonth(), ('bearer '+ getCookie('userToken')));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:DASHBOARD_DETAILS,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

export function dashboardSMSStats(){
  const request = Api.get(DASHBOARD_SMS_STATS_URL, ('bearer '+ getCookie('userToken')));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:DASHBOARD_SMS_STATS,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}
