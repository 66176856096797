import React from 'react';
import {Card, CardContent, Skeleton} from '@mui/material';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';

import {dateFormat} from '../../lib/utils';
import ErrorMsg from './ErrorMsg';
import Heading from './Heading';
import SubHeading from './SubHeading';


const LastActivity = ({dashboardData}) => {
  //Added check for actual data/ here we need to fetch the data through API
  if (isEmpty(dashboardData) || !dashboardData.data?.lastActivity ) {
    return <Skeleton variant="rounded" width={'100%'} height={205} />
  }
  return (
    <Card>
      <CardContent>
        <Heading>Last Activity</Heading>
        {dashboardData.success && dashboardData.data?.lastActivity ? dashboardData.data.lastActivity?.date ?
          <>
            <SubHeading>
                At {dateFormat(dashboardData.data.lastActivity.date)} to {dashboardData.data.lastActivity.contactCount} contacts
            </SubHeading>
            <div className='dashboard-card-container overflow-auto'>
              <p className='last-activity-msg'>
                {dashboardData.data.lastActivity.text}
              </p>
            </div>
          </> :
          <div className='dashboard-card-container'>
            <ErrorMsg msg="No activity Available" />
          </div> :
          <div className='dashboard-card-container'>
            <ErrorMsg msg={dashboardData?.err?.msg} />
          </div>
        }
      </CardContent>
    </Card>
  )

}


function mapStateToProps(state){
  return{
    dashboardData: state.dashboard.smsStats,

  }
}

export default connect(mapStateToProps)(LastActivity)
