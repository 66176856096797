
const MAX_RETRY_ATTEMPTS = 100; //Total Retries
const DEFAULT_POLL_INTERVAL_SECONDS = 3; // Every 3 sec
const DEFAULT_POLL_TIMEOUT_SECONDS = 300; // 5 Mins


function sleep(durationInS){
  return new Promise(resolve=>{
    setTimeout(resolve,durationInS*1000)
  })
}

function getDelayGenerator(pollIntervalInS, error) {
  //return delay in seconds
  let retryDelay = pollIntervalInS;
  let retryAfter = 0
  if (error instanceof Error){
    retryAfter = error?.header?.retryAfter
  }
  if (retryAfter) {
    let value
    try {
      value = Number.parseFloat(retryAfter)
    } catch(e) {
      console.log("Parsing retryAfter headers failed")
    }
    if (!isNaN(value)){
      retryDelay = value
    }
  }
  return retryDelay
}

class BrowserHost {
  fetch(url, fetchParams){
    return fetch(url, fetchParams)
  }
}

export class Polling {
  constructor(
    url,
    fetchParams = {},
    responseHandler,
    maxAttempt = MAX_RETRY_ATTEMPTS,
    timeoutInS = DEFAULT_POLL_TIMEOUT_SECONDS,
    pollIntervalInS = DEFAULT_POLL_INTERVAL_SECONDS
  ) {
    this.maxAttempt = maxAttempt
    this.timeoutInS = timeoutInS;
    this.pollIntervalInS = pollIntervalInS
    this.url = url
    this.fetchParams = fetchParams
    this.existingDelay = 0
    this.host = new BrowserHost()
    this.attemptCount = 0;
    this.totalDelay = 0;
    this.responseHandler = responseHandler
    this.isPollingCancelled = false
  }

  shouldRetry(error){
    let shouldRetry = false
    let nextDelay = getDelayGenerator(this.pollIntervalInS, error)
    if (nextDelay + this.existingDelay < this.timeoutInS){
      shouldRetry = true;
      this.existingDelay += nextDelay
    }
    if (shouldRetry) {
      console.log("Retrying the poll request on specific error...", error.message)
    } else {
      console.log("nextDelay + existingDelay is less than polltimeout so do not retry...")
    }
    return shouldRetry
  }

  timeoutWrapper(...args) {
    return this._fn(...args)
  }

  async apiCall() {
    let statusResponse = await this.host.fetch(this.url, this.fetchParams)
    let responseJson;
    if (statusResponse.ok){
      responseJson = await statusResponse.json()
      if (this.responseHandler(responseJson)) {
        return responseJson
      } else {
        throw new Error("Job not completed") 
      }
    }else{
      throw new Error("got the Polling error response")
    }
  }

  poll() {
    return this.apiCall()
      .then(resolution =>resolution)
      .catch(async (err) => {
        if (this.getIsCancelled()) {
          console.log('Polling is cancelled')
          return Promise.reject(err)  
        }

        this.attemptCount++;
        if(this.attemptCount === this.maxAttempt){
          console.log("failed after retries, giving up")
          return Promise.reject(err)
        }

        if (typeof this.shouldRetry === "function"){
          try{
            const shouldContinue = await this.shouldRetry(err)
            if (!shouldContinue){
              console.log("failed after retries, giving up")
              return Promise.reject(err)
            }
          }catch(err){
            console.log("failed after retries, giving up")
            return Promise.reject(err)
          }
        }

        let delay = this.pollIntervalInS
        this.totalDelay +=delay
        return sleep(delay).then(()=>this.poll())

      })
  }

  cancelPoll () {
    this.isPollingCancelled = true
  }

  getIsCancelled() {
    return this.isPollingCancelled
  }
  
}
