import React, {Component} from 'react';
import {Button, TextField} from '@mui/material';
import {connect} from 'react-redux';

import {sumbitInstitute} from '../../actions/adminPanel';
import Select from '../../components/Select';
import CONSTANT from './constant';

class CreateInstitute extends Component{
  constructor(){
    super();
    this.state = {
      name : '',
      instituteType : '',
      info : '',
      address : '',
      mobile : '',
      emailId  : '',
      senderId : '',
      operatorId: '',
      dltPrincipalEntityId: '',
      city : '',
      smsType : ["PROMO"],
      defaultSmsType : "PROMO",
      smsCount : {
        'TRANS' : 0,
        'PROMO' : 0
      }
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.sumbitResponse && nextProps.sumbitResponse.success){
      alert('Institute Successfully Created and its credentials are \nUsername : '+nextProps.sumbitResponse.data.userId + '\nPassword : '+nextProps.sumbitResponse.data.password);
      this.props.hideSumbitRequest(true);
    } else{
      alert(nextProps.sumbitResponse.err.msg);
    }
  }
  handleChange = (event) => {this.setState({instituteType: event.target.value});}
  handleOperatorId = (event) => {this.setState({operatorId: event.target.value});}
  handleChangeSMSDefaultType = (e) => {this.setState({defaultSmsType: e.target.value});}
  handleChangeSMSType = ({target: {value: smsType}}) => {
    let {smsCount} =this.state;
    if(smsType.indexOf("PROMO") == -1){
      smsCount.PROMO = 0;
    }
    if(smsType.indexOf("TRANS") == -1){
      smsCount.TRANS = 0;
    }
    if(smsType.indexOf("OTP") == -1){
      smsCount.OTP = 0;
    }
    if(smsType.length==1){
      this.setState({smsType:smsType,defaultSmsType : smsType[0],smsCount});
    } else{
      this.setState({smsType:smsType,smsCount});
    }
  }
  sumbitRequest = () =>{
    let {name,instituteType,mobile,senderId,smsCount} = this.state; 
    //added new sender id
    let allowed_sender_id=[3,4,5,6]
    if(name == ''){
      alert('Please enter Name');
    } else if(instituteType == ''){
      alert('Please select Institute Type');
    } else if(mobile == '' || mobile.length != 10){
      alert('Please enter mobile Number');
    } else if(senderId == '' || !allowed_sender_id.includes(senderId.length)){
      alert('Please enter Header');
    } else if(isNaN(smsCount.PROMO) || isNaN(smsCount.TRANS)){
      alert('Please enter correct sms count');
    } else{
      this.props.sumbitInstitute(this.state);
    }
  }
  hideSumbitRequest = () => {
    this.props.hideSumbitRequest();
  }
  render(){
    let {name,instituteType,info,address,mobile,emailId,senderId,operatorId,dltPrincipalEntityId,city,smsType,defaultSmsType,smsCount} = this.state;
    const isMobile = window.isMobile();
    return(
      <div style={{width:isMobile ? '90%': '50%',margin:'0 auto',paddingBottom:50, display: 'flex', flexDirection: 'column', gap: 16}}>
        <p style={{paddingTop:10}}>Create Institute</p>
        <TextField
          value={name}
          fullWidth
          onChange={(e)=>{this.setState({name : e.target.value})}}
          size="small"
          required
          variant="standard"
          label="Name" />
        <Select
          label="Institute Type"
          size="small"
          required
          value={instituteType}
          fullWidth
          onChange={this.handleChange}
          options={['School', 'Business', 'Company', 'College', 'Coaching','Factory',  'Hospital', 'Medical', 'Hotel', 'Restaurant']}
        />
        <TextField
          value={info}
          fullWidth
          onChange={(e)=>{this.setState({info : e.target.value})}}
          size="small"
          variant="standard"
          label="Info" />
        <Select
          label="Operator ID"
          small="small"
          value={operatorId}
          fullWidth
          onChange={this.handleOperatorId}
          options={CONSTANT.OPERATOR_OPTS}
        />
        <TextField
          label="DLT ID"
          maxLength="100"
          fullWidth
          value={dltPrincipalEntityId}
          size="small"
          variant="standard"
          onChange={(e)=>{this.setState({dltPrincipalEntityId : e.target.value})}} />
        <TextField
          label="SenderId"
          maxLength="6"
          fullWidth
          value={senderId}
          size="small"
          required
          variant="standard"
          onChange={(e)=>{this.setState({senderId : e.target.value})}} />
        <Select
          label="SMS Type"
          small="small"
          value={smsType}
          fullWidth
          onChange={this.handleChangeSMSType}
          multiple
          options={["PROMO", "TRANS", "OTP"]}
        />
        {
          smsType.indexOf("PROMO") > -1 ?
            <TextField
              value={smsCount.PROMO}
              fullWidth
              onChange={(e)=>{let smsCount = this.state.smsCount; smsCount.PROMO = e.target.value; this.setState({smsCount})}}
              size="small"
              required
              type="number"
              variant="standard"
              label="Promotional SMS Count" />
            : null
        }
        {
          smsType.indexOf("TRANS") > -1 ?
            <TextField
              value={smsCount.TRANS}
              fullWidth
              size="small"
              required
              type="number"
              variant="standard"
              onChange={(e)=>{let smsCount = this.state.smsCount; smsCount.TRANS = e.target.value; this.setState({smsCount})}}
              label="Transactional SMS Count" />
            : null
        }
        {
          smsType.indexOf("OTP") > -1 ?
            <TextField
              value={smsCount.OTP}
              fullWidth
              type="number"
              size="small"
              variant="standard"
              onChange={(e)=>{let smsCount = this.state.smsCount; smsCount.OTP = e.target.value; this.setState({smsCount})}}
              label="OTP SMS Count" />
            : null
        }
        {
          smsType.length > 1 ?
            <Select
              label="Default SMS Type"
              small="small"
              value={defaultSmsType}
              fullWidth
              onChange={this.handleChangeSMSDefaultType}
              options={[{value:"PROMO", name: 'Promotional'}, {value: "TRANS", name:'Transactional'}, {value: "OTP", name: 'OTP'}]}
            />
            
            : null
        }
        <TextField
          label="Mobile Number"
          maxLength="10"
          value={mobile}
          fullWidth
          required
          variant="standard"
          type="number"
          onChange={(e)=>{this.setState({mobile : e.target.value})}}
          size="small" />
        <TextField
          value={emailId}
          fullWidth
          onChange={(e)=>{this.setState({emailId : e.target.value})}}
          size="small"
          variant="standard"
          label="Email" />
        <TextField
          className={"edit"}
          value={address}
          fullWidth
          size="small"
          variant="standard"
          onChange={(e)=>{this.setState({address : e.target.value})}}
          label="Address" />
        <TextField
          className={"edit"}
          value={city}
          fullWidth
          size="small"
          variant="standard"
          onChange={(e)=>{this.setState({city : e.target.value})}}
          label="City" />
        <div>
          <Button onClick={this.sumbitRequest} variant='contained'>
                Submit
          </Button>
          <Button onClick={this.hideSumbitRequest}>
                Cancel
          </Button>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    sumbitResponse : state.adminPanel.sumbitAgentResponse
  }
}
export default connect(mapStateToProps,{sumbitInstitute})(CreateInstitute)
